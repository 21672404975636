import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CustomApi } from "../../hooks/useAPi";
import { useDispatch } from "react-redux";

import {
  requiredError,
  maxLengthError,
  patternAddress,
} from "../../const/validationMessage";
import { adminRegister } from "../../types";
import { Title } from "../../components/common/title";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useCookies } from "react-cookie";

export const EmployeeEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<boolean>(false);
  const [cookies] = useCookies(["admin_id", "auth_id"]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<adminRegister>({
    mode: "onBlur",
    criteriaMode: "all",
  });

  const closeDialog = () => {
    setDialog(!dialog);
    navigate("/employee/list");
  };
  const resetAsyncForm = useCallback(async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{ id: adminRegister["id"] }>(
      "admin/show",
      {
        id: Number(params.id!),
      },
      cookies.auth_id,
      cookies.admin_id
    );
    reset(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  }, []);

  // データの取得が完了したら再度初期化;
  useEffect(() => {
    resetAsyncForm();
  }, [resetAsyncForm]);
  const onSubmit = async (values: adminRegister) => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi(
      "admin/update",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200) {
      navigate("/error");
    }
    // 完了したら。
    setDialog(!dialog);
  };

  return (
    <div className="cont_wrapper">
      <Title title={"従業員編集"} />
      <form>
        {/* お名前 */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>お名前</p>
          </div>
          {errors.name && <p className="alert">{errors.name?.message}</p>}
          <input
            {...register("name", {
              required: requiredError,
              maxLength: maxLengthError(16),
            })}
            type="text"
            src=""
            placeholder="お名前 を入力してください"
            alt=""
          />
        </div>
        {/* メールアドレス */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <label htmlFor="email">メールアドレス</label>
          </div>
          {errors.email && <p className="alert">{errors.email?.message}</p>}
          <input
            {...register("email", {
              required: requiredError,
              maxLength: maxLengthError(255),
              pattern: patternAddress,
            })}
            type="email"
            id="email"
            src=""
            placeholder="メールアドレス を入力してください"
            alt=""
          />
        </div>
      </form>
      <div className="button_flex">
        <button
          onClick={() => navigate("/employee/list")}
          className="outline_pink_btn"
        >
          キャンセル
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="normal_btn"
        >
          更新
        </button>
      </div>
      {dialog ? <BaseDialog type="complete" close={() => closeDialog()} /> : ""}
    </div>
  );
};
