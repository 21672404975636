import { typeShop } from "../../types";

type props = {
  close: () => void;
  item: typeShop;
};
export const StoreDetailDialog = (props: props) => {
  return (
    <div className="store_detail">
      <div className="dialog_space">
        <table className="dialog_table">
          <tr>
            <th colSpan={2}>店舗管理</th>
          </tr>
          <tr>
            <th>店舗ID</th>
            <td>{props.item.id}</td>
          </tr>
          <tr>
            <th>店舗名</th>
            <td>{props.item.store_name}</td>
          </tr>
          <tr>
            <th>店舗URL</th>
            <td>{props.item.store_url}</td>
          </tr>
          <tr>
            <th>代表者名</th>
            <td>{props.item.rep_name}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{props.item.phone_number}</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>{props.item.post_number}</td>
          </tr>
          {/* <tr>
            <th>都道府県</th>
            <td>{props.item.prefectures}</td>
          </tr>
          <tr>
            <th>市区町村</th>
            <td>{props.item.municipalities}</td>
          </tr>
          <tr>
            <th>番地</th>
            <td>{props.item.streets}</td>
          </tr>
          <tr>
            <th>建物名</th>
            <td>{props.item.building}</td>
          </tr> */}
          <tr>
            <th colSpan={2}>口座管理</th>
          </tr>
          <tr>
            <th>銀行名</th>
            <td>{props.item.bank_name}</td>
          </tr>
          <tr>
            <th>支店名</th>
            <td>{props.item.branch_name}</td>
          </tr>
          <tr>
            <th>種別</th>
            <td>{props.item.account_type}</td>
          </tr>
          <tr>
            <th>口座番号</th>
            <td>{props.item.account_number}</td>
          </tr>
          <tr>
            <th>口座名義(カナ)</th>
            <td>{props.item.account_name}</td>
          </tr>
        </table>
      </div>
      <button onClick={props.close} className="normal_btn">
        閉じる
      </button>
    </div>
  );
};
