import { useQRCode } from "next-qrcode";
import { Title } from "../components/common/title";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Qr = () => {
  const { Image } = useQRCode();
  const navigate = useNavigate();
  const [cookies] = useCookies(["store_url"]);

  return (
    <div id="qr" className="cont_wrapper">
      <Title title={"店舗注文用QRコード"} />

      <div className="qr_design">
        <Image
          text={`https://encas.jp/${cookies.store_url}/login/`}
          options={{
            type: "image/jpeg",
            quality: 0.3,
            level: "M",
            margin: 3,
            scale: 4,
            width: 1000,
            color: {
              dark: "#b0b0ae",
              light: "#1c1c1b",
            },
          }}
        />
        <span className="qr_border_top_left" />
        <span className="qr_border_top_right" />
        <span className="qr_border_bottom_left" />
        <span className="qr_border_bottom_right" />
      </div>
      <div className="qr_link">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://encas.jp/${cookies.store_url}/login`}
        >{`https://encas.jp/${cookies.store_url}/login`}</a>
      </div>

      <p className="alert">
        QR画像を長押し、または右クリックで画像を保存可能です。
      </p>

      <button className="normal_btn" onClick={() => navigate("/product/list")}>
        閉じる
      </button>
    </div>
  );
};
