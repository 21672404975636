import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomApi } from "../../hooks/useAPi";
import { typePaymentList } from "../../types/index";
import { reWiteDate } from "../../hooks/useReDate";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const PaymentHistory = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<typePaymentList[]>([]);
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);
  const navigate = useNavigate();

  const getPaymentList = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{ store_id: number }>(
      "adminpayment/show",
      {
        store_id: cookies.store_id!,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      dispatch({ type: "loading", payload: false });
      navigate("/error");
    }

    dispatch({ type: "loading", payload: false });
    setData(data.data.responsData);
  };
  useEffect(() => {
    getPaymentList();
  }, []);

  return (
    <div className="cont_wrapper">
      <div className="payment_button_area">
        <Link
          to={"/payment/request"}
          className="request_button outline_pink_btn"
        >
          出金申請
        </Link>
      </div>
      <table className="table">
        <tr>
          <th>日時</th>
          <th>出金可能額</th>
          <th>ステータス</th>
        </tr>
        {data.map((item, i) => {
          return (
            <tr key={i}>
              <td>{reWiteDate(item.created_at)}</td>
              <td>¥{item.apply_amount}</td>
              <td>{item.transfer_status === 0 ? "未" : "済"}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
