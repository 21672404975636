import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Error = () => {
  const [cookies] = useCookies(["role"]);
  return (
    <div className="cont_wrapper">
      <div id="error">
        <h3>
          処理に問題が発生しました。
          <br />
          再度行ってください。
        </h3>
        {Number(cookies.role) === 3 ? (
          <Link className="normal_btn" to={"/store/list"}>
            店舗一覧へ
          </Link>
        ) : (
          <Link className="normal_btn" to={"/product/list"}>
            商品一覧へ
          </Link>
        )}
      </div>
    </div>
  );
};
