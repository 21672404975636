import React, { useState } from "react";
import { MailForm } from "../components/common/mailregister/mailForm";
import { MailComplete } from "../components/common/mailregister/mailComplete";
export const MailRegister = () => {
  const [sendMail, setSendMail] = useState<Boolean>(false);
  const [address, setAddress] = useState<string>("");
  return (
    <div className="cont_wrapper">
      {sendMail ? (
        <MailComplete address={address} />
      ) : (
        <MailForm
          changeState={() => setSendMail(true)}
          setAddress={setAddress}
        />
      )}
    </div>
  );
};
