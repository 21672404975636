import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <div style={{ float: "right", display: "flex", paddingRight: "20px" }}>
        <Link
          to={`/service/policy`}
          target="_blank"
          style={{
            color: " #ecc2c2",
            textDecoration: "underline",
            marginLeft: "1.5em",
          }}
        >
          <p>利用規約</p>
        </Link>
        <br />
        <Link
          to={`/privacy/policy`}
          target="_blank"
          style={{
            color: " #ecc2c2",
            textDecoration: "underline",
            marginLeft: "1.5em",
          }}
        >
          <p>プライバシーポリシー</p>
        </Link>
        <br />
        <Link
          to={`/tokushoho`}
          target="_blank"
          style={{
            color: " #ecc2c2",
            textDecoration: "underline",
            marginLeft: "1.5em",
          }}
        >
          <p>特定商取引法に関する表記</p>
        </Link>
      </div>
    </>
  );
};
