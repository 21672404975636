import React from "react";
type props = {
  close: () => void;
  success: () => void;
};
export const PaymentConfirm = (props: props) => {
  return (
    <div id="paymentConfirm">
      <p className="dialogText">出金申請を行いますか？</p>
      <div className="button_flex">
        <button className="outline_pink_btn" onClick={props.close}>
          キャンセル
        </button>
        <button onClick={props.success} className="normal_btn">
          申請
        </button>
      </div>
    </div>
  );
};
