import React, { useState, useEffect } from "react";
import { typeSalesList } from "../../types/index";
import { CustomApi } from "../../hooks/useAPi";
import { reWiteDate } from "../../hooks/useReDate";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export const SalesList = () => {
  const date = new Date();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<
    "daily" | "shop" | "customer" | "product"
  >("daily");
  const [data, setData] = useState<typeSalesList[]>([]);

  const [changeCount, setChangeCount] = useState<number>(0);
  const [currentDate, setCurrentDate] = useState({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  const [cookies] = useCookies(["store_id", "role", "auth_id", "admin_id"]);
  const getSalesList = async () => {
    dispatch({ type: "loading", payload: true });

    const data =
      Number(cookies.role) === 3
        ? await CustomApi<{
            year: number;
            month: number;
          }>(
            "order/admin/index",
            {
              year: currentDate.year,
              month: currentDate.month,
            },
            cookies.auth_id,
            cookies.admin_id
          )
        : await CustomApi<{
            store_id: number;
            year: number;
            month: number;
          }>(
            "order/store/index",
            {
              store_id: cookies.store_id,
              year: currentDate.year,
              month: currentDate.month,
            },
            cookies.auth_id,
            cookies.admin_id
          );
    await setData(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  };

  // ソート機能
  const sort = () => {
    let sortData: typeSalesList[];
    const currentData = Array.from(data);
    switch (currentTab) {
      case "daily":
        sortData = currentData.sort((a, b) => {
          if (b.created_at < a.created_at) return -1;
          if (b.created_at > a.created_at) return 1;
          return 0;
        });
        break;
      case "shop":
        sortData = currentData.sort((a, b) => {
          if (b.store_name < a.store_name) return -1;
          if (b.store_name > a.store_name) return 1;
          return 0;
        });
        break;
      case "customer":
        sortData = currentData.sort((a, b) => {
          if (b.nickname < a.nickname) return -1;
          if (b.nickname > a.nickname) return 1;
          return 0;
        });
        break;
      case "product":
        sortData = currentData.sort((a, b) => {
          if (b.product_name < a.product_name) return -1;
          if (b.product_name > a.product_name) return 1;
          return 0;
        });
        break;
    }
    return sortData;
  };
  // 日付変更処理
  const changeDate = () => {
    date.setMonth(date.getMonth() + changeCount);

    setCurrentDate({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    });
  };
  useEffect(() => {
    getSalesList();
  }, []);
  useEffect(() => {
    setData(sort());
  }, [currentTab]);
  useEffect(() => {
    changeDate();
  }, [changeCount]);
  useEffect(() => {
    getSalesList();
  }, [currentDate]);
  return (
    <div className="cont_wrapper">
      <div className="date_area">
        <div
          className="date_arrow arrow_left"
          onClick={() => setChangeCount(changeCount - 1)}
        ></div>

        <p className="date">
          {currentDate.year}年{currentDate.month}月
        </p>
        {changeCount === 0 ? null : (
          <div
            className="date_arrow arrow_right"
            onClick={() => setChangeCount(changeCount + 1)}
          ></div>
        )}
      </div>
      <div className="switch_button_area">
        <button
          className={currentTab === "daily" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("daily")}
        >
          日
        </button>
        <span></span>
        <button
          className={currentTab === "customer" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("customer")}
        >
          {Number(cookies.role) === 3 ? "ユーザー" : "顧客名"}
        </button>
        {Number(cookies.role) === 3 ? (
          <>
            <span></span>
            <button
              className={currentTab === "shop" ? "current" : ""}
              type="button"
              onClick={() => setCurrentTab("shop")}
            >
              店舗
            </button>
          </>
        ) : (
          ""
        )}
        {Number(cookies.role) === 1 ? (
          <>
            <span></span>
            <button
              className={currentTab === "product" ? "current" : ""}
              type="button"
              onClick={() => setCurrentTab("product")}
            >
              商品
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="table_wrap">
        <table className="table">
          <tr>
            <th>日時</th>
            {Number(cookies.role) === 3 ? <th>店舗名</th> : null}
            <th>{Number(cookies.role) === 3 ? "ユーザー" : "顧客名"}</th>
            <th>商品名</th>
            <th>売上(税込)</th>
          </tr>
          {data.map((item, i) => {
            return (
              <tr key={i}>
                <td>{reWiteDate(item.created_at)}</td>
                {Number(cookies.role) === 3 ? <td>{item.store_name}</td> : null}

                <td>{item.nickname}</td>
                <td>{item.product_name}</td>
                <td>¥{item.total_amount}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
