import React, { useEffect } from "react";
import { Title } from "../components/common/title";
export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="policy" className="cont_wrapper">
      <Title title="プライバシーポリシー" />

      <div className="top_detail">
        <div className="text_wrapper">
          <div className="top_text_flex">
            <p
              style={{
                marginLeft: "1.5em",
              }}
            >
              「EnCas」サイト（以下、「当サイト」といいます）を管理運営するEnCas（以下、｢当サイト｣といいます。）は、運営各店舗に徹底した指導、教育を行い、お客様が安心して当サイトをご利用頂けるよう、プライバシーポリシー（個人情報保護に関する基本方針）を定め個人情報保護に取り組んでいます。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 1 条 個人情報の利用について <br />
              当サイトが管理運営する当サイトでは、ご利用内容によってはお客様の同意のもと、氏名、メールアドレス、住所等の個人情報を収集させて頂く場合があり、これらの情報は、当サイトからのメールマガジンの提供等、お客様が希望するサービス、情報の提供および当サイトをご利用する際にお客様の利便性を向上させるために利用させていただきます。
              <br />
              <p>
                当サイトが管理運営する当サイトでは、ご利用内容によってはお客様の同意のもと、氏名、メールアドレス、住所等の個人情報を収集させて頂く場合があり、これらの情報は、当サイトからのメールマガジンの提供等、お客様が希望するサービス、情報の提供および当サイトをご利用する際にお客様の利便性を向上させるために利用させていただきます。
                <br />
                <p>
                  （1）当サイトが収集するお客様の個人情報は、収集目的を明確にした上で、目的の範囲内に限ります。
                  また、個人情報の利用は、その収集目的から逸脱しない範囲とします。
                </p>
                <p>
                  （2）収集した個人情報は、同目的の範囲内で利用しており、お客様の事前承諾なしに目的外利用や第三者への提供は行いません。
                  また個人情報に関する不正アクセス、紛失、改竄、漏洩を防ぐための適切な処置を行います。
                </p>
                <p>
                  （3）当サイトは、当サイトが保有する個人情報に関して適用される法令、規範を遵守します。
                </p>
                <p>
                  （4）当サイトは、個人情報保護に関する管理の体制と仕組みについて継続的改善を実施します。
                </p>
              </p>
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 2 条 個人情報の第三者への開示について
              <br />
              当サイトは、お客様のプライバシーを尊重し、個人情報を保護するために細心の注意を払っています。当サイトでは、当サイトを利用された内容に伴い取得した個人情報を、お客様の事前の同意なく第三者に対して開示することはありません。ただし、次の各号の場合には、お客様の事前の同意なく、当サイトはお客様の個人情報を開示できるものとします。
              <p>（1）法令に基づき開示を求められた場合</p>
              <p>
                （2）当グループ、他のお客様またはその他の第三者の権利、利益、名誉、信用等を保護するために必要であると当サイトが判断した場合
              </p>
              <p>（3）お客様が自分の個人情報の開示を事前承認した場合</p>
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 3 条 アクセスログの収集について <br />
              当サイトは、お客様がお使いのコンピュータハードウエアおよびソフトウエア情報を収集することがあります。この情報には、ＩＰアドレス、ブラウザの種類、ドメイン名、アクセス時間、および参照されるウェブサイトのＵＲＬ等が含まれます。当グループはこれらの情報を不正アクセスの監視、セキュリティやサービス品質の向上、当サイトの利用動向等の調査、分析を行うための一般的な統計情報として利用するものであり、個人情報の特定はしていません。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 4 条 Cookie(クッキー)について
              <br />
              当グループは、当サイト運営のためにお客様の「Cookie(クッキー)情報」を収集し、参照しています。
              「Cookie(クッキー)情報」とは、Cookie(クッキー)という業界標準の技術を利用して、記録保管を目的として、ウェブサイトが利用者のコンピューターのハードディスクに転送する情報のことです。Cookie(クッキー)によってお客様の個人情報を収集することはありません。
              お客様のブラウザ設定によりクッキーの機能を無効にすることができますが、当サイトが提供するサービスの一部をご利用いただけない場合があります。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 5 条 セキュリティについて
              <br />
              当サイトは、個人情報の取り扱いに際して、個人情報管理責任者を置き、収集した個人情報はアクセス権を持つ社員が限定されたサーバに保管し、適切かつ厳重な予防措置を講じることでお客様の個人情報保護に努めます。
              当サイトはセキュリティ確保のため、個人情報の入力時には、これらの情報が傍受、妨害または改ざんされることを防ぐ目的で
              SSL（Secure Sockets
              Layer）技術を使用しております。お客様が入力した情報は SSL
              暗号化通信により保護されます。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第 6 条 変更及び通知について <br />
              当サイトはこのプライバシーポリシー（個人情報保護に関する基本方針）の内容を、事前の予告なく変更することがあります。お客様へその都度ご連絡はいたしかねますので、ご利用の際には本ページの最新の内容をご参照ください。
            </p>
          </div>
        </div>
        <p
          style={{
            marginLeft: "1.5em",
          }}
        >
          最終更新日 : 2023年4月25日
        </p>
      </div>
      <button onClick={() => window.close()} className="normal_btn">
        閉じる
      </button>
    </div>
  );
};
