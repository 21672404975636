export const reWiteDate = (time: string) => {
  let date: string | Date = new Date(time);
  if (date.getMinutes() < 10) {
    date =
      date.getFullYear() +
      "/" +
      ((date.getMonth() % 12) + 1) +
      "/" +
      date.getDate() +
      " " +
      date.getHours() +
      ":0" +
      date.getMinutes();
  } else {
    date =
      date.getFullYear() +
      "/" +
      ((date.getMonth() % 12) + 1) +
      "/" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes();
  }

  return date;
};
