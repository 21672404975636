import type { RouteObject } from "react-router-dom";
import { Header } from "../components/header/header";
import { HeaderSub } from "../components/header/headerSub";
import { ItemUpdate } from "../pages/item/ItemUpdate";
import { ItemCreate } from "../pages/item/ItemCreate";
import { ProductList } from "../pages/product/ProductList";
import { Top } from "../pages/Top";
import { PaymentRequest } from "../pages/payment/PaymentRequest";
import { PaymentList } from "../pages/payment/PaymentList";
import { PaymentHistory } from "../pages/payment/PaymentHistory";
import { SalesList } from "../pages/sales/SalesList";
import { OrderList } from "../pages/order/OrderList";
import { Category } from "../pages/category/Category";
import { Qr } from "../pages/Qr";
import { Error } from "../pages/Error";
import { StoreRegist } from "../pages/store/StoreRegist";
import { StoreEdit } from "../pages/store/StoreEdit";
import { StoreList } from "../pages/store/StoreList";
import { Login } from "../pages/login/Login";
import { PassReset } from "../pages/login/PassReset";
import { PassResetCompleted } from "../pages/login/PassResetCompleted";
import { SignUp } from "../pages/login/SignUp";
import { EmployeeList } from "../pages/employee/EmployeeList";
import { EmployeeRegist } from "../pages/employee/EmployeeRegist";
import { EmployeeEdit } from "../pages/employee/EmployeeEdit";
import { ServicePolicy } from "../pages/ServicePolicy";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Tokushoho } from "../pages/Tokushoho";
import { MailRegister } from "../pages/MailRegister";
import { RouteAuthGuard } from "../components/common/routeAuthGuard";
import { PassResetMail } from "../pages/login/PassResetMail";
import { Footer } from "../components/footer/footer";
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Top />,
  },
  {
    path: "/",
    element: <Footer />,
  },
  {
    path: "/",
    element: <RouteAuthGuard element={<Header />} />,
    children: [
      // TODO:Authのリダイレクト処理などのmiddleware追加
      // ナビ付き(ログイン後)のヘッダーを利用する場合はこっち
      {
        path: "/product/update/:id",
        element: <RouteAuthGuard element={<ItemUpdate />} />,
      },
      {
        path: "/product/create",
        element: <RouteAuthGuard element={<ItemCreate />} />,
      },
      {
        path: "product/list",
        element: <RouteAuthGuard element={<ProductList />} />,
      },
      {
        path: "payment/request",
        element: <RouteAuthGuard element={<PaymentRequest />} />,
      },
      {
        path: "payment/history", //店舗側管理画面の出金管理
        element: <RouteAuthGuard element={<PaymentHistory />} />,
      },
      {
        path: "payment/list", //管理者側管理画面の出金管理
        element: <RouteAuthGuard element={<PaymentList />} />,
      },
      {
        path: "sales/list", //管理者側売上管理(または権限によりタブを変えるだけにしようか)
        element: <RouteAuthGuard element={<SalesList />} />,
      },
      {
        path: "order/list", //管理者側売上管理(または権限によりタブを変えるだけにしようか)
        element: <RouteAuthGuard element={<OrderList />} />,
      },
      {
        path: "employee/list", //従業員一覧
        element: <RouteAuthGuard element={<EmployeeList />} />,
      },
      {
        path: "employee/regist", //従業員登録
        element: <RouteAuthGuard element={<EmployeeRegist />} />,
      },
      {
        path: "employee/edit/:id", //従業員編集
        element: <RouteAuthGuard element={<EmployeeEdit />} />,
      },

      {
        path: "store/edit", //店舗編集画面
        element: <StoreEdit />,
      },
      {
        path: "store/list", //管理者側店舗管理(または権限によりタブを変えるだけにしようか)
        element: <RouteAuthGuard element={<StoreList />} />,
      },
      {
        path: "category/list", //カテゴリー画面
        element: <RouteAuthGuard element={<Category />} />,
      },
      {
        path: "qr", //カテゴリー画面
        element: <RouteAuthGuard element={<Qr />} />,
      },
    ],
  },
  {
    path: "/",
    element: <HeaderSub />,
    children: [
      // ナビなし(ログイン前)のヘッダーを利用する場合はこっち
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/mail/reset",
        element: <PassResetMail />,
      },
      {
        path: "/password_reset",
        element: <PassReset />,
      },
      {
        path: "/password_reset/completed",
        element: <PassResetCompleted />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "mail/register",
        element: <MailRegister />,
      },
      {
        path: "/store/regist", //店舗登録画面
        element: <StoreRegist />,
      },
      {
        path: "service/policy", //利用規約
        element: <ServicePolicy />,
      },
      {
        path: "privacy/policy", //プライバシーポリシー
        element: <PrivacyPolicy />,
      },
      {
        path: "tokushoho", //特定商取引法に基づく表記
        element: <Tokushoho />,
      },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
];
