import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

type props = {
  close: () => void;
};

export const LogoutDialog = (props: props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    "role",
    "store_id",
    "store_name",
    "store_url",
    "auth_id",
    "admin_id",
  ]);

  const logout = () => {
    removeCookie("role");
    removeCookie("store_id");
    removeCookie("store_name");
    removeCookie("store_url");
    removeCookie("auth_id");
    removeCookie("admin_id");
    dispatch({ type: "loading", payload: false });
    navigate(`/login`);
  };
  return (
    <div id="logout">
      <p className="alert">ログアウトしますか？</p>
      <div className="button_flex">
        <button onClick={() => props.close()} className="outline_pink_btn">
          キャンセル
        </button>
        <button onClick={() => logout()} type="submit" className="normal_btn">
          ログアウト
        </button>
      </div>
    </div>
  );
};
