import React, { useState, useEffect } from "react";
import { Title } from "../../components/common/title";
import { CustomApi } from "../../hooks/useAPi";
import { typeCategory } from "../../types/index";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Category = () => {
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState<typeCategory[]>([]);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<"deleteConfirm" | "complete">(
    "deleteConfirm"
  );
  const [deleteTargetId, setDeleteTargetId] = useState<typeCategory["id"]>();
  const [inputCategory, setInputCategory] =
    useState<typeCategory["category_name"]>("");
  const getCategoryList = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{ store_id: number }>(
      "category/index",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );

    if (data.status !== 200) {
      navigate("/error");
    }
    setData(data.data.responseData);
    dispatch({ type: "loading", payload: false });
  };
  const clickDelete = (id: number) => {
    setDeleteTargetId(id);
    setDialogType("deleteConfirm");
    setDialog(true);
  };
  const dialogSuccess = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{
      id: typeCategory["id"];
      deleted_by: string;
    }>(
      "category/destroy",
      {
        deleted_by: "system",
        id: deleteTargetId!,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      navigate("/error");
    }
    dispatch({ type: "loading", payload: false });
    setDialogType("complete");
  };
  const closeDialog = () => {
    getCategoryList();
    setDialog(!dialog);
    setDialogType("deleteConfirm");
  };
  const insertCategory = async () => {
    if (!inputCategory) {
      return;
    }
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{
      category_name: typeCategory["category_name"];
      store_id: number;
    }>(
      "category/create",
      {
        store_id: cookies.store_id,
        category_name: inputCategory,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      navigate("/error");
    }
    dispatch({ type: "loading", payload: false });
    setDialogType("complete");
    setInputCategory("");
    getCategoryList();
  };
  useEffect(() => {
    getCategoryList();
  }, []);
  return (
    <div id="category" className="cont_wrapper">
      <Title title={"カテゴリー管理"} />
      <table className="ctg_info table">
        <tbody>
          <tr>
            <th>カテゴリー名</th>
            <th className="diagonal_line"></th>
          </tr>
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                <p>{item.category_name}</p>
              </td>
              <td>
                <button
                  className="outline_pink_btn"
                  type="button"
                  onClick={() => clickDelete(item.id)}
                >
                  削除
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="ctg_add_area">
        <div className="add_item_name">
          <input
            onChange={(e) => setInputCategory(e.target.value)}
            value={inputCategory}
            type="text"
            id=""
            name=""
            placeholder="新規追加"
          />
        </div>
        <div className="ctg_add_button">
          <button
            onClick={() => insertCategory()}
            className="normal_btn"
            type="button"
          >
            追加
          </button>
        </div>
      </div>
      {dialog ? (
        <BaseDialog
          type={dialogType}
          success={() => dialogSuccess()}
          close={() => closeDialog()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
