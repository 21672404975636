import React from "react";
type props = {
  image?: string;
  product_name?: string;
  description?: string;
  amount?: number;
  stock?: number | string;
  success?: () => void;
  close: () => void;
};
export const DeleteConfirm = (props: props) => {
  return (
    <>
      <p
        className={`alert dialogText ${
          props.product_name ? "" : "deleteConfirm"
        }`}
      >
        本当に削除しますか？
      </p>
      {props.product_name ? (
        <div className="dialog_inner">
          {props.product_name ? <p>{props.product_name}</p> : ""}
          {props.description ? <p>{props.description}</p> : ""}

          <div className="button_flex">
            {props.amount ? <p>¥{props.amount}(税込)</p> : ""}
            {props.stock ? <p>在庫:{props.stock}</p> : ""}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="button_flex">
        <button onClick={props.success} className="normal_btn">
          削除
        </button>
        <button className="outline_pink_btn" onClick={props.close}>
          キャンセル
        </button>
      </div>
    </>
  );
};
