import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import mobile from "../assets/img/mobile.svg";
import payment from "../assets/img/payment.svg";
import magazine from "../assets/img/magazine_01.jpg";
import magazine1 from "../assets/img/magazine_02.png";
import magazine2 from "../assets/img/magazine_03.jpg";
import { Footer } from "../components/footer/footer";
export const Top = () => {
  return (
    <>
      <header id="header_lp">
        <div className="header_top_lp">
          <img src={logo} className="header_icon" alt="" />
          <div className="header_button">
            <div id="signup">
              <Link className="signup_button" to={"/mail/register"}>
                新規登録
              </Link>
            </div>
            <div id="login">
              <Link className="login_button" to={"/login"}>
                ログイン
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="top_wrapper">
        <h2>EnCas(エンキャス)とは？</h2>
        <img src={logo} className="store_top_icon" alt="" />
        <div className="top_detail">
          <div className="text_wrapper">
            <div className="top_text_flex">
              <div className="pink_circle"></div>
              <p>
                飲食店、特にコンセプトバーカジュアルバー及びコンセプトカフェ・ホストクラブ・キャバクラなどを中心に「仕事で行けない」「推したい」「サプライズしたい」Etcの理由で、実店舗に行けないときに遠隔でドリンクを投げ込むことができます。
              </p>
            </div>
          </div>
          <div className="text_wrapper">
            <div className="top_text_flex">
              <div className="pink_circle"></div>

              <p>
                遠隔オーダーコミュニティサポートになります。
                <br />
                ・お店の周年
                <br />
                ・担当、推しのバースデー
                <br />
                ・お店のイベント
                <br />
                ・コンカフェゲスト出勤
                <br />
                などの際キャスト、ゲスト問わずにオーダーをプレゼントすることができます。
              </p>
            </div>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="pc_flex">
            <div className="pc_flex_text">
              <h3>顧客管理にも便利</h3>
              <div className="top_text_flex text_wrapper">
                <div className="pink_circle"></div>
                <p>
                  ユーザーがSNSを登録している場合はすぐに誰だかわかるからサンキューメッセージも送りやすい。
                </p>
              </div>
              <div className="top_text_flex text_wrapper">
                <div className="pink_circle"></div>
                <p>
                  ユーザーの売上も簡単に管理OK。
                  <br />
                  キャストやユーザーの遠隔ランキングにも活用できます。
                </p>
              </div>
            </div>
            <div className="store_cont_icon">
              <img src={mobile} alt="" />
            </div>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="pc_flex">
            <div className="pc_flex_text">
              <h3>安心・安全な入金管理</h3>
              <div className="top_text_flex text_wrapper">
                <div className="pink_circle"></div>
                <p className="top_text_flex">
                  毎月１〜１５日の間に出金予約をしていただければ申請月の末日に登録口座にお振り込み（金額によって＋３営業日以内）となります。
                </p>
              </div>
              <div className="top_text_flex text_wrapper">
                <div className="pink_circle"></div>
                <p className="top_text_flex">
                  月次の売上は1日 0:00 ～ 末日 23:59が基準となります。
                </p>
              </div>
            </div>
            <div className="store_cont_icon">
              <img src={payment} alt="" />
            </div>
          </div>
        </div>
        <div className="magazine">
          <img src={magazine} className="header_icon" alt="" />
          <img src={magazine1} className="header_icon" alt="" />
          <img src={magazine2} className="header_icon" alt="" />
        </div>
        <Footer />
      </div>
    </>
  );
};
