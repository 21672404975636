import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomApi } from "../../hooks/useAPi";
import {
  requiredError,
  minLengthError,
  maxLengthError,
  patternHankaku,
  patternAddress,
} from "../../const/validationMessage";
import { typeEmployeeRegister } from "../../types";
import { Title } from "../../components/common/title";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useCookies } from "react-cookie";

export const EmployeeRegist = () => {
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm<typeEmployeeRegister>({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const onSubmit = async (values: typeEmployeeRegister) => {
    dispatch({ type: "loading", payload: true });
    values.store_id = cookies.store_id;
    values.role = 2;
    const data = await CustomApi<typeEmployeeRegister>(
      "admin/user_create",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200 && data.status !== 400) {
      navigate("/error");
    } else if (data.status === 400) {
      alert(
        `${data.data.responsMassage}
他のメールアドレスをご利用ください`
      );
      return;
    } else if (data.status === 200) {
      setDialog(!dialog);
    }
  };

  const closeDialog = () => {
    setDialog(!dialog);
    navigate("/employee/list");
  };

  return (
    <div className="cont_wrapper">
      <Title title={"従業員登録"} />
      <form>
        {/* お名前 */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>お名前</p>
          </div>
          {errors.name && <p className="alert">{errors.name?.message}</p>}
          <input
            {...register("name", {
              required: requiredError,
              maxLength: maxLengthError(16),
            })}
            type="text"
            src=""
            placeholder="お名前 を入力してください"
            alt=""
          />
        </div>
        {/* メールアドレス */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <label htmlFor="email">メールアドレス</label>
          </div>
          {errors.email && <p className="alert">{errors.email?.message}</p>}
          <input
            {...register("email", {
              required: requiredError,
              maxLength: maxLengthError(255),
              pattern: patternAddress,
            })}
            type="email"
            id="email"
            src=""
            placeholder="メールアドレス を入力してください"
            alt=""
          />
        </div>
        {/* パスワード */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>パスワード</p>
          </div>

          {errors.password && (
            <p className="alert">{errors.password?.message}</p>
          )}
          <input
            {...register("password", {
              required: requiredError,
              minLength: minLengthError(8),
              maxLength: maxLengthError(64),
              pattern: patternHankaku,
              onBlur: () => {
                if (getValues("pass_confirm")) {
                  trigger("pass_confirm");
                }
              },
            })}
            type="password"
            src=""
            placeholder="半角英数字8文字以上"
            alt=""
          />
        </div>
        {/* パスワード(確認) */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>パスワード(確認)</p>
          </div>

          {errors.pass_confirm && (
            <p className="alert">{errors.pass_confirm?.message}</p>
          )}
          <input
            {...register("pass_confirm", {
              required: requiredError,
              minLength: minLengthError(8),
              maxLength: maxLengthError(64),
              pattern: patternHankaku,
              validate: (value) => {
                return (
                  value === getValues("password") || "パスワードが一致しません"
                );
              },
            })}
            type="password"
            src=""
            placeholder="半角英数字8文字以上"
            alt=""
          />
        </div>
      </form>
      <div className="button_flex">
        <Link to={"/employee/list"} className="outline_pink_btn">
          キャンセル
        </Link>
        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="normal_btn"
        >
          登録
        </button>
      </div>
      {dialog ? <BaseDialog type="complete" close={() => closeDialog()} /> : ""}
    </div>
  );
};
