import React, { useEffect } from "react";
import { CompleteDialog } from "./completeDialog";
import { PaymentCompleteConfirm } from "./paymentCompleteConfirm";
import { OrderConfirm } from "./orderConfirmDialog";
import { PaymentConfirm } from "./paymentConfirm";
import { DeleteConfirm } from "./deleteConfirm";
import { LogoutDialog } from "./logoutDialog";
import { StoreDetailDialog } from "./storeDetailDialog";
import { typeShop } from "../../types";
type props = {
  type:
    | "delete"
    | "deleteConfirm"
    | "complete"
    | "paymentConfirm"
    | "paymentCompleteConfirm"
    | "orderConfirm"
    | "logout"
    | "storeDetail";
  success?: () => void;
  image?: string;
  product_name?: string;
  description?: string;
  amount?: number;
  stock?: number | string;
  earnings?: number;
  item?: typeShop;
  close: () => void;
};
export const BaseDialog = (props: props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);
  const DialogInnerComponent = () => {
    switch (props.type) {
      case "deleteConfirm":
        return (
          <DeleteConfirm
            image={props.image}
            product_name={props.product_name}
            description={props.description}
            amount={props.amount}
            stock={props.stock}
            close={props.close}
            success={props.success}
          />
        );
      case "paymentConfirm":
        return <PaymentConfirm close={props.close} success={props.success!} />;
      case "paymentCompleteConfirm":
        return (
          <PaymentCompleteConfirm
            close={props.close}
            success={props.success!}
          />
        );
      case "orderConfirm":
        return <OrderConfirm close={props.close} success={props.success!} />;
      case "complete":
        return <CompleteDialog close={props.close} />;
      case "storeDetail":
        return <StoreDetailDialog item={props.item!} close={props.close} />;
      case "logout":
        return <LogoutDialog close={props.close} />;
    }
  };
  return (
    <div id="dialog">
      <div className="dialog_box">{DialogInnerComponent()}</div>
    </div>
  );
};
