import React, { useState, useEffect } from "react";
import { item, typeCategory } from "../../types/index";
import { Pagination } from "../../components/common/pagination";
import { Link } from "react-router-dom";
import { CustomApi } from "../../hooks/useAPi";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
export const ProductList = () => {
  const [cookies] = useCookies(["store_id", "role", "auth_id", "admin_id"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ダイアログのタイプを選択する
  const [dialogType, setDialogType] = useState<"complete" | "deleteConfirm">(
    "deleteConfirm"
  );
  const [category, setCategory] = useState<typeCategory[]>([]);
  // 何番目のページか保有する
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [responseData, setResponseData] = useState<item[]>([]);
  // 表示用のステート(ページネーション毎に表示数を保有する)
  const [showProductList, setShowProductList] = useState<item[]>([]);

  // フィルター結果を保有するステート(フィルター結果を保持して、showProductListステートに表示ページ毎のデータを登録する)
  const [productList, setProductList] = useState<item[]>([]);

  // ページネーションのために、何件データがあるかを保有する
  const [productLength, setProductLength] = useState<number>(0);
  const [dialog, setDialog] = useState<boolean>(false);
  const [propsDialog, setPropsDialog] = useState<item>();
  // 1ページあたりの表示数
  const maxProductView = 10;
  const getProductLists = async () => {
    dispatch({ type: "loading", payload: true });
    const categoryData = await CustomApi<{ store_id: number }>(
      "category/index",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    const data = await CustomApi<{ store_id: number }>(
      "product/index",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200 || categoryData.status !== 200) {
      dispatch({ type: "loading", payload: false });
      navigate("/error");
      return;
    }
    setCategory(categoryData.data.responseData);
    setResponseData(data.data.responsData);
    setProductList(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  };
  const currentProductLists = () => {
    let pageList: item[] = [];
    productList.map((item, i) => {
      // 表示分のみをフィルターして表示する
      // (ページ1 -1 )*10 = 0
      // ページ1 * 10 -1 = 9
      // 0番目から表示されるため最大数より1件ぶん引く
      if (
        (currentPage - 1) * maxProductView <= i &&
        currentPage * maxProductView - 1 >= i
      ) {
        pageList.push(item);
      }
    });
    setShowProductList(pageList);
  };
  useEffect(() => {
    getProductLists();
  }, []);
  useEffect(() => {
    currentProductLists();
  }, [productList, currentPage]);
  const changeCategory = (type: string = "0") => {
    const fillData =
      type === "0"
        ? responseData
        : responseData.filter(
            (item) => item.product_category_id === Number(type)
          );
    setProductList(fillData);
    setProductLength(fillData.length);
    setCurrentPage(1);
    currentProductLists();
  };

  const clickDelete = (item: item) => {
    setDialog(true);
    setPropsDialog(item);
  };

  const closeDialog = () => {
    getProductLists();
    setDialog(!dialog);
    setDialogType("deleteConfirm");
  };
  const successDelete = async () => {
    dispatch({ type: "loading", payload: true });

    const data: any = await CustomApi<{ id: number; deleted_by: string }>(
      "product/destroy",
      {
        id: propsDialog!.id,
        deleted_by: "system",
      },
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200) {
      navigate("/error");
    }
    setDialogType("complete");
    // 完了したら。
  };
  return (
    <div className="cont_wrapper">
      <div className="product_top_buttons">
        <div className="select_wrap">
          <select onChange={(e) => changeCategory(e.target.value)}>
            <option value="0">全ての商品</option>
            {category.map((item, i) => (
              <option key={i} value={item.id}>
                {item.category_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {showProductList.map((item, i) => (
        <div key={i} className="product_item">
          <div className="product_item_img">
            <img src={item.image_url} className="" alt="" />
          </div>
          <div className="product_item_info">
            {Number(cookies.role) === 1 ? (
              <div className="product_item_buttons">
                <Link
                  className="normal_btn product_edit_button"
                  to={`/product/update/${item.id}`}
                >
                  編集
                </Link>
                <button
                  className="product_delete_button outline_pink_btn"
                  type="button"
                  onClick={() => clickDelete(item)}
                >
                  削除
                </button>
              </div>
            ) : (
              ""
            )}

            <p className="product_item_title">{item.product_name}</p>
            <p className="product_item_detail">{item.description}</p>
            <p className="product_stock">
              在庫:
              <span>
                {item.infinite_inventory_flag === 0
                  ? item.stock!.toLocaleString()
                  : "無制限"}
              </span>
            </p>
            <p className="product_price">
              <span>¥{item.amount.toLocaleString()}</span>(税込)
            </p>
          </div>
        </div>
      ))}
      {productLength > maxProductView ? (
        <Pagination
          currentPage={currentPage}
          pageLength={productLength}
          maxView={maxProductView}
          setCurrent={setCurrentPage}
        />
      ) : null}
      {dialog ? (
        <BaseDialog
          type={dialogType}
          image={propsDialog!.image}
          product_name={propsDialog!.product_name}
          description={propsDialog!.description}
          amount={propsDialog!.amount}
          stock={
            propsDialog!.infinite_inventory_flag === 0
              ? propsDialog!.stock!.toLocaleString()
              : "無制限"
          }
          close={() => closeDialog()}
          success={() => successDelete()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
