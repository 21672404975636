import React, { useState } from "react";
import close_icon from "../../assets/img/close_icon.svg";
import menu_icon from "../../assets/img/menu_icon.svg";
import logo from "../../assets/img/logo.svg";
import icon_06 from "../../assets/img/icon_06.svg";
import icon_07 from "../../assets/img/icon_07.svg";
import { Link, NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { BaseDialog } from "../dialog/baseDialog";
import { useCookies } from "react-cookie";

/**
 * メニューオブジェクトの型定義
 */
type MenuList = {
  icon?: string;
  link: string;
  name: string;
};

/**
 * 権限を代入する変数用の型定義
 */
type AuthRole = 1 | 2 | 3;
export const Header = () => {
  const [cookies] = useCookies(["store_name", "role"]);

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);

  /**
   * メニュー開閉イベント
   */
  const toggle = () => {
    setToggleMenu(!toggleMenu);
  };
  /**
   * 権限の代入
   *
   *1:店舗管理者 2:店舗従業員 3:システム管理者
   *
   */
  const AuthRole = Number(cookies.role) as AuthRole;
  /**
   * メニュー項目オブジェクト
   */
  let menuList: MenuList[];
  switch (AuthRole) {
    case 1:
      // 店舗管理者メニュー
      menuList = [
        {
          icon: "icon_01.svg",
          link: "/product/list",
          name: "商品一覧",
        },
        {
          icon: "icon_02.svg",
          link: "/product/create",
          name: "商品登録",
        },
        {
          icon: "icon_03.svg",
          link: "/order/list",
          name: "注文一覧",
        },
        {
          icon: "icon_04.svg",
          link: "/sales/list",
          name: "売上管理",
        },
        {
          icon: "icon_05.svg",
          link: "/payment/history",
          name: "出金管理",
        },
        {
          link: "/employee/list",
          name: "従業員管理",
        },
        {
          link: "/category/list",
          name: "カテゴリー管理",
        },
      ];
      break;
    case 2:
      // 店舗従業員メニュー
      menuList = [
        {
          icon: "icon_01.svg",
          link: "/product/list",
          name: "商品一覧",
        },
        {
          icon: "icon_03.svg",
          link: "/order/list",
          name: "注文一覧",
        },
      ];
      break;
    case 3:
      // システム管理者メニュー
      menuList = [
        {
          icon: "icon_08.svg",
          link: "/store/list",
          name: "店舗管理",
        },
        {
          icon: "icon_04.svg",
          link: "/sales/list",
          name: "売上管理",
        },
        {
          icon: "icon_05.svg",
          link: "/payment/list",
          name: "出金管理",
        },
      ];
      break;
  }

  return (
    <>
      <header id="header">
        <div className="header_top">
          <img src={logo} className="header_icon" alt="" />

          <div className="header-center">
            {AuthRole === 1 || AuthRole === 2 ? (
              <div className="authName">
                <p>{cookies.store_name}</p>
              </div>
            ) : (
              <p>システム管理者</p>
            )}
          </div>

          <div className="header-right">
            <div id="hamburger">
              <img
                onClick={toggle}
                className={`menu_icon ${toggleMenu ? "hidden" : ""}`}
                src={menu_icon}
                alt=""
              />
              <img
                onClick={toggle}
                className={`close ${toggleMenu ? "" : "hidden"}`}
                src={close_icon}
                alt=""
              />
            </div>
          </div>
          <div className={`menu ${toggleMenu ? "" : "hidden"}`}>
            <div className="menu_wrapper">
              <>
                {AuthRole !== 3 ? (
                  <div className="button_area">
                    {AuthRole === 1 ? (
                      <>
                        <Link
                          onClick={toggle}
                          className="edit_button"
                          to="/store/edit"
                        >
                          <img src={icon_06} alt="" />
                        </Link>
                        <span></span>
                      </>
                    ) : null}

                    <Link onClick={toggle} className="qr_button" to="/qr">
                      <img src={icon_07} alt="" />
                    </Link>
                  </div>
                ) : null}

                <ul>
                  {menuList!.map((item, i) => (
                    <li key={i}>
                      <Link onClick={toggle} to={item.link}>
                        <div className="arrow arrow-right"></div>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <p
                      onClick={() => {
                        toggle();
                        setDialog(true);
                      }}
                    >
                      <div className="arrow arrow-right"></div>
                      ログアウト
                    </p>
                  </li>
                </ul>
              </>
            </div>
          </div>
        </div>
        <div className="list_menu">
          <ul>
            {menuList!.map((item, i) =>
              item.icon ? (
                <li key={i}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                    to={item.link}
                  >
                    <svg>
                      <use
                        xlinkHref={
                          require(`../../assets/img/${item.icon}`) + "#root"
                        }
                      />
                    </svg>
                  </NavLink>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
        </div>
      </header>
      {dialog ? (
        <BaseDialog type="logout" close={() => setDialog(false)} />
      ) : (
        ""
      )}
      <Outlet />
    </>
  );
};
