import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Title } from "../../components/common/title";
import { Pagination } from "../../components/common/pagination";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { typeMember } from "../../types";
import { CustomApi } from "../../hooks/useAPi";
import { useDispatch } from "react-redux";
import { reWiteDate } from "../../hooks/useReDate";
import { useCookies } from "react-cookie";

export const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  // 何番目のページか保有する
  const [currentPage, setCurrentPage] = useState<number>(1);

  // 表示用のステート(ページネーション毎に表示数を保有する)
  const [showEmployeeList, setShowEmployeeList] = useState<typeMember[]>([]);

  // フィルター結果を保有するステート(フィルター結果を保持して、showEmployeeListステートに表示ページ毎のデータを登録する)
  const [employeeList, setEmployeeList] = useState<typeMember[]>([]);

  // ページネーションのために、何件データがあるかを保有する
  const [EmployeeLength, setEmployeeLength] = useState<number>(0);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<"deleteConfirm" | "complete">(
    "deleteConfirm"
  );
  const [targetId, setTargetId] = useState<typeMember["id"]>();
  // 1ページあたりの表示数
  const maxEmployeeView = 10;
  const getEmployeeLists = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{ store_id: number }>(
      "employee/index",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    setEmployeeList(data.data.responsData);
    setEmployeeLength(data.data.responsData.length);
    dispatch({ type: "loading", payload: false });
  };

  const currentEmployeeLists = () => {
    let pageList: typeMember[] = [];
    employeeList.map((employee, i) => {
      // 表示分のみをフィルターして表示する
      // (ページ1 -1 )*10 = 0
      // ページ1 * 10 -1 = 9
      // 0番目から表示されるため最大数より1件ぶん引く
      if (
        (currentPage - 1) * maxEmployeeView <= i &&
        currentPage * maxEmployeeView - 1 >= i
      ) {
        pageList.push(employee);
      }
    });
    setShowEmployeeList(pageList);
  };
  const clickDelete = (id: typeMember["id"]) => {
    setTargetId(id);
    setDialog(true);
  };
  const deleteEmployee = async () => {
    setDialog(false);
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{ id: number }>(
      "admin/destroy",
      {
        id: targetId!,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      dispatch({ type: "loading", payload: false });
      navigate("/error");
    }
    dispatch({ type: "loading", payload: false });
    setDialogType("complete");
    setDialog(true);
  };
  const closeDialog = () => {
    getEmployeeLists();
    setDialog(!dialog);
    setDialogType("deleteConfirm");
  };
  useEffect(() => {
    getEmployeeLists();
  }, []);
  useEffect(() => {
    currentEmployeeLists();
  }, [employeeList, currentPage]);

  return (
    <div id="employeeList" className="cont_wrapper">
      <div className="regist_button_area">
        <Title title={"従業員一覧"} />
        <Link
          to={"/employee/regist"}
          className="request_button outline_pink_btn"
        >
          新規作成
        </Link>
      </div>

      <div className="employeeTable">
        <table className="table">
          <tr>
            <th>登録日時</th>
            <th>登録名</th>
            <th>メールアドレス</th>
            <th></th>
            <th></th>
          </tr>
          {showEmployeeList.map((employee, i) => (
            <tr key={i}>
              <td>{reWiteDate(employee.created_at)}</td>
              <td>{employee.name}</td>
              <td>{employee.email}</td>
              <td>
                <Link
                  className="detail_button normal_btn"
                  type="button"
                  to={`/employee/edit/${employee.id}`}
                >
                  編集
                </Link>
              </td>
              <td>
                <button
                  className="detail_button outline_pink_btn"
                  type="button"
                  onClick={() => clickDelete(employee.id)}
                >
                  削除
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>

      {EmployeeLength > maxEmployeeView ? (
        <Pagination
          currentPage={currentPage}
          pageLength={EmployeeLength}
          maxView={maxEmployeeView}
          setCurrent={setCurrentPage}
        />
      ) : null}
      {dialog ? (
        <BaseDialog
          type={dialogType}
          success={() => deleteEmployee()}
          close={() => closeDialog()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
