import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { CustomApi } from "../../hooks/useAPi";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import {
  requiredError,
  maxLengthError,
  maxLengthNumberError,
  minPriceError,
  patternNumber,
} from "../../const/validationMessage";
import { item, typeCategory } from "../../types";
import { Title } from "../../components/common/title";
import { BaseDialog } from "../../components/dialog/baseDialog";
export const ItemUpdate = () => {
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [dialog, setDialog] = useState<boolean>(false);
  const [category, setCategory] = useState<typeCategory[]>([]);
  const [preview, setPreview] = useState<string>();
  const [image, setImage] = useState<any>();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<item>();

  const getCategory = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    const categoryData = await CustomApi<{ store_id: number }>(
      "category/index",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    setCategory(categoryData.data.responseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAsyncForm = useCallback(async () => {
    const data: any = await CustomApi<{ id: number }>(
      "product/show",
      {
        id: Number(params.id),
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      navigate("/error");
    }
    reset(data.data.responsData);
    dispatch({ type: "loading", payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // データの取得が完了したら再度初期化
  useEffect(() => {
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    resetAsyncForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);
  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image[0]));
    }
  }, [image]);
  const onSubmit = async (values: item) => {
    if (image) {
      values.image = image[0];
    }
    const data: any = await CustomApi<item>(
      "product/update",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    // 完了したら。
    if (data.status !== 200) {
      navigate("/error");
    }
    setDialog(!dialog);
  };
  const closeDialog = () => {
    setDialog(!dialog);
    navigate("/product/list");
  };
  return (
    // TODO:バリデーションのUI追加
    <div className="cont_wrapper">
      <Title title={"商品編集"} />
      <form>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>商品画像</p>
          </div>
          <div className="product_image">
            <label htmlFor="file_upload">
              ファイルを選択して下さい
              <input
                type="file"
                id="file_upload"
                accept="image/*"
                onChange={(event) => setImage(event.target.files)}
              />
            </label>
          </div>
          {preview ? (
            <div className="product_item_img product_item_upload">
              <img src={preview} alt="" />
            </div>
          ) : (
            <div className="product_item_img product_item_upload">
              <img src={getValues("image_url")} alt="" />
            </div>
          )}
        </div>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>商品名</p>
          </div>
          {errors.product_name && (
            <p className="alert">{errors.product_name?.message}</p>
          )}
          <input
            {...register("product_name", {
              required: requiredError,
              maxLength: maxLengthError(16),
            })}
            type="text"
            src=""
            placeholder="商品名が入ります"
            alt=""
          />
        </div>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>カテゴリ</p>
          </div>
          {errors.product_category_id && (
            <p className="alert">{errors.product_category_id?.message}</p>
          )}
          <div className="select_wrap">
            <select
              id=""
              {...register("product_category_id", {
                required: requiredError,
              })}
            >
              <option selected value="" disabled>
                選択してください
              </option>
              {category.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.category_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="form-title">
            <span className="any">任意</span>
            <p>商品説明（100文字以内）</p>
          </div>
          {errors.description && (
            <p className="alert">{errors.description?.message}</p>
          )}
          <textarea
            {...register("description", {
              maxLength: maxLengthError(100),
            })}
          />
        </div>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>価格（100円以上）</p>
          </div>
          {errors.amount && <p className="alert">{errors.amount?.message}</p>}
          <input
            {...register("amount", {
              required: requiredError,
              min: minPriceError(100),
              maxLength: maxLengthNumberError(9),
              pattern: patternNumber,
            })}
            type="text"
            placeholder="¥000,000,000"
          />
        </div>
        <div className="stock">
          <div className="form-title">
            <span className="required">必須</span>
            <p>在庫数</p>
          </div>
          {errors.stock && (
            <p
              className={`alert ${
                watch("infinite_inventory_flag") === 1 ? "disabled" : ""
              }`}
            >
              {errors.stock?.message}
            </p>
          )}

          <div className="form_checkbox_flex">
            <input
              className={
                watch("infinite_inventory_flag") === 1 ? "disabled" : ""
              }
              {...register("stock", {
                required:
                  getValues("infinite_inventory_flag") !== 1
                    ? requiredError
                    : "",
                maxLength: maxLengthNumberError(3),
                pattern: patternNumber,
              })}
              type="text"
              disabled={watch("infinite_inventory_flag") === 1}
            />
            <div className="checkbox_area">
              <input
                id="infinite_stock"
                {...register("infinite_inventory_flag")}
                onChange={(e) => {
                  setValue(
                    "infinite_inventory_flag",
                    getValues("infinite_inventory_flag") === 0 ? 1 : 0
                  );
                  setValue("stock", null);
                }}
                type="checkbox"
                checked={watch("infinite_inventory_flag") === 1}
              />
              <label className="checkbox" htmlFor="infinite_stock" />
              <span>在庫無制限</span>
            </div>
          </div>
        </div>
      </form>
      <div className="button_flex">
        <button
          onClick={() => navigate("/product/list")}
          className="outline_pink_btn"
        >
          キャンセル
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="normal_btn"
        >
          更新
        </button>
      </div>
      {dialog ? <BaseDialog type="complete" close={() => closeDialog()} /> : ""}
    </div>
  );
};
