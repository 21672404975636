import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Title } from "../components/common/title";
export const ServicePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="policy" className="cont_wrapper">
      <Title title="利用規約" />
      <div className="top_detail">
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第1条（適用） <br />
              本規約は，ユーザー及び登録店舗・登録主催者（以下ユーザー）と株式会社NIG（以下当社）との間の本サービスの利用に関わる一切の関係に適用されるものとします。
              <br />
              当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下,「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
              <br />
              本規約の定めが前項の個別規定の定めと矛盾する場合には，個別規定において特段の定めなき限り，個別規定の定めが優先されるものとします。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第2条（利用登録） <br />
              本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれに対する承認を登録希望者に通知することによって，利用登録が完了するものとします。
              <br />
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                <span style={{ color: "var(--main-color)" }}>
                  20歳未満の飲酒は法律で禁止されています。20歳未満の方の本サービスに係るお申込みは、お受けできません。
                </span>
                <br />
              </p>
              <p>
                当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
                <br />
                <p>利用登録の申請に際して虚偽の事項を届け出た場合 </p>
                <p>本規約に違反したことがある者からの申請である場合</p>
                <p>その他，当社が利用登録を相当でないと判断した場合</p>
              </p>
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第3条（ユーザーIDおよびパスワードの管理）
              <br />
              ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを管理するものとします。
              <br />
              ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
              <br />
              ユーザーID及びパスワードが第三者に使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第4条（売買契約） <br />
              本サービスにおいては，ユーザーが当社に対して購入の申し込みをし，これに対して当社が当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。なお，当該商品の所有権は，ユーザーが決済を完了した際に，各店舗及びユーザーに移転するものとします。{" "}
              <br />
              また、本サービスにおいては，登録店舗・登録主催者が当社サイトに商品を登録する場合は法令および公序良俗を遵守し、商品表現に関しても配慮を行うこととします。
              当社は，ユーザーが以下のいずれかの事由に該当する場合には，当該ユーザーに事前に通知することなく，前項の売買契約を解除することができるものとします。{" "}
              <br />
              ユーザーが本規約に違反した場合 ユーザーの決済が完了しない場合{" "}
              <br />
              登録店舗・登録主催者の商品が欠品している場合 <br />
              登録店舗・登録主催者の商品において法令および公序良俗に違反するものがあった場合{" "}
              <br />
              その他当社とユーザーの信頼関係が損なわれたと認める場合 <br />
              本サービスに関する決済方法，配送方法，購入の申し込みのキャンセル方法，または返品方法等については，別途当社が定める方法によります。
              <br />
              <br />
              利用店舗は，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。
              <br />
              月額基本利用料は11,000円（税込）になります。
              <br />
              <br />
              利用店舗が利用料金の支払を遅滞した場合には，利用店舗は年14．6％の割合による遅延損害金を支払うものとします。
              <br />
              クレジットカードによるお支払いについては、お客様の指定するクレジットカード会社の規約に従うものとします。クレジットカード会社から当社にカード無効による売上否認の通知があった場合、お客様は当社が別途定める方法により支払いを行うものとします。
              <br />
              お客様とクレジットカード会社との間で支払いに係る紛争が生じたときは、当該当事者双方で解決するものとし、当社は一切の責任を負わないものとします。
              <br />
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第5条（知的財産権）
              <br />
              本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当社及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第6条（禁止事項）
              <br />
              ユーザーは，本サービスの利用にあたり，以下の行為をしてはならないものとします。{" "}
              <br />
              法令または公序良俗に違反する行為 犯罪行為に関連する行為 <br />
              本サービスに含まれる著作権，商標権その他の知的財産権を侵害する行為{" "}
              <br />
              当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為{" "}
              <br />
              本サービスによって得られた情報を商業的に利用する行為 <br />
              当社のサービスの運営を妨害するおそれのある行為 <br />
              不正アクセスをし，またはこれを試みる行為 <br />
              他のユーザーに関する個人情報等を収集または蓄積する行為 <br />
              他のユーザーに成りすます行為 <br />
              当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
              <br />
              その他，当社が不適切と判断する行為 <br />
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第7条（本サービスの提供の停止等） <br />
              当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
              本サービスにかかるコンピュータシステムの保守点検または更新を行う場合{" "}
              <br />
              地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
              <br />
              コンピュータまたは通信回線等が事故により停止した場合 <br />
              その他，当社が本サービスの提供が困難と判断した場合 <br />
              当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第8条（利用制限および登録抹消） <br />
              １.当社は，以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
              本規約の以下いずれかの条項に違反した場合 <br />
              (1)入力情報に誤りがあり、連絡がとれない場合 <br />
              (2)暴力団等反社会的勢力、これらの者と密接な関係を有する者である場合
              <br />
              (3)
              支払能力がない場合、債務超過、無資力、破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始、その他の倒産手続開始の申立てが行われた場合、解散を決議した場合、または営業休止状態である場合
              <br />
              (4)
              過去に本規約に違反し本サービスの利用の停止処分を受けたことがある者である場合
              <br />
              (5) 法律行為を有効に行う能力を有していない場合 <br />
              (6)決済事業者等から、お客様の本サービスの利用を停止する要請があった場合
              <br />
              (7)
              決済事業者等から、決済又は収納代行サービスの利用停止措置が取られた場合
              <br />
              ２．本サービスを利用するにあたり、お客様はご自身の責任と費用負担で、必要な通信環境及び端末機器を用意するものとし、当社はこれらに起因する事項について一切責任を負いません。
              <br />
              ３．お客様は、ご自身の判断、責任、費用負担で本サービスを利用するものとします。
              <br />
              ４．当社は、お客様が、暴力団等反社会的勢力、これらの者と密接な関係を有する者、これらに準ずる者に該当すると判断した場合は、事前にお客様に対して通知することなく、本サービスの利用の停止を行うことができるものとします。なお、当該停止によってお客様に生じた損害については、一切責任を負いません。
              <br />
              5．当社は、当社の判断により、いつでも本サービスの全部又は一部の終了又は変更を行うことができるものとします。
              <br />
              6．天変地異等の不可抗力、その他当社が必要と判断する場合は、事前にお客様に対して通知を行うことで、本サービスの全部又は一部の提供を取りやめることができるものとします。ただし、緊急を要する場合は、お客様に対する事前の通知を省略することができるものとします。
              <br />
              7．お客様は、決済事業者等がシステム障害、天変地異等の不可抗力、その他必要と判断したことにより、本サービスの全部又は一部の提供を中断又は取りやめることがあることを承諾します。
              <br />
              8．登録事項に虚偽の事実があることが判明した場合 <br />
              9.料金等の支払債務の不履行があった場合 <br />
              １０.当社からの連絡に対し，一定期間返答がない場合 <br />
              １１.その他，当社が本サービスの利用を適当でないと判断した場合
              <br />
              １２.第三者へのアカウントの貸与、第三者のアカウントを使用する行為
              <br />
              １３.転売利益を得る目的で商品等を購入する行為 <br />
              １４．前第８条によって生じたお客様の損害や不利益について、当社は一切責任を負いません。
              <br />
              当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第9条（保証の否認および免責事項） <br />
              当社は,本サービスに事実上または法律上の瑕疵（安全性,信頼性,正確性,完全性,有効性,特定の目的への適合性,セキュリティなどに関する欠陥,エラーやバグ,権利侵害などを含みます。）がないことを保証するものではありません。
              <br />
              当社は,本サービスによってユーザーに生じたあらゆる損害について,一切の責任を負いません。ただし,本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合,この免責規定は適用されませんが,この場合であっても,当社は,当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し,または予見し得た場合を含みます。）について一切の責任を負いません。
              <br />
              当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
            </p>
          </div>
        </div>

        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第10条（サービス内容の変更等）
              <br />
              当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第11条（利用規約の変更）
              <br />
              当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第12条（個人情報の取扱い）
              <br />
              当社は，本サービスの利用によって取得する個人情報については，当社「
              <Link
                to={`/privacy/policy`}
                target="_blank"
                style={{
                  color: " #ecc2c2",
                  textDecoration: "underline",
                }}
              >
                プライバシーポリシー
              </Link>
              」に従い適切に取り扱うものとします。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第13条（通知または連絡）
              <br />
              ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第14条（権利義務の譲渡の禁止）
              <br />
              ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
            </p>
          </div>
        </div>
        <div className="text_wrapper">
          <div className="top_text_flex">
            <div className="pink_circle"></div>
            <p>
              第15条（準拠法・裁判管轄）
              <br />
              本規約の解釈にあたっては，日本法を準拠法とします。なお，本サービスに関しては，国際物品売買契約に関する国際連合条約の適用を排除するものとします。
              本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。
            </p>
          </div>
        </div>
      </div>
      <button onClick={() => window.close()} className="normal_btn">
        閉じる
      </button>
    </div>
  );
};
