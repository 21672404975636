import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminRegister } from "../../types";
import { Title } from "../../components/common/title";
import { CustomApi } from "../../hooks/useAPi";
import { useDispatch } from "react-redux";
import {
  requiredError,
  minLengthError,
  maxLengthError,
  patternHankaku,
  diffPasswordError,
  consentError,
} from "../../const/validationMessage";
import { useCookies } from "react-cookie";

export const SignUp = () => {
  const url = `${window.location.protocol}//${window.location.host}`;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["admin_id", "auth_id"]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<adminRegister>({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const onSubmit = async (values: adminRegister) => {
    dispatch({ type: "loading", payload: true });
    values.id = Number(searchParams.get("id"));
    const data = await CustomApi<adminRegister>(
      "admin/update",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      navigate("/error");
    }
    dispatch({ type: "loading", payload: false });
    navigate(
      `/store/regist?id=${data.data.responsData.id}&key=${values.shop_code}`
    );
  };
  return (
    <div className="cont_wrapper">
      <Title title={"新規登録"} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <label>お名前</label>
          </div>
          {errors.name && <p className="alert">{errors.name?.message}</p>}
          <input
            {...register("name", {
              required: requiredError,
              maxLength: maxLengthError(16),
            })}
            type="text"
          />
        </div>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <label>パスワード</label>
          </div>

          {errors.password && (
            <p className="alert">{errors.password?.message}</p>
          )}
          <input
            {...register("password", {
              required: requiredError,
              minLength: minLengthError(8),
              maxLength: maxLengthError(64),
              pattern: patternHankaku,
            })}
            type="password"
            src=""
            placeholder="半角英数字8文字以上"
            alt=""
          />
        </div>
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <label>パスワード(確認)</label>
          </div>

          {errors.pass_confirm && (
            <p className="alert">{errors.pass_confirm?.message}</p>
          )}

          <input
            {...register("pass_confirm", {
              required: requiredError,
              minLength: minLengthError(8),
              maxLength: maxLengthError(64),
              pattern: patternHankaku,
              validate: (value) => {
                return value === getValues("password") || diffPasswordError;
              },
            })}
            type="password"
            src=""
            placeholder="半角英数字8文字以上"
            alt=""
          />
        </div>
        <div>
          <div className="form-title">
            <span className="any">任意</span>
            <label>店舗コード</label>
          </div>

          <input
            {...register("shop_code")}
            type="text"
            src=""
            placeholder="お持ちの方はご入力ください"
            alt=""
          />
        </div>
        {/* 権限 */}
        <div style={{ visibility: "hidden" }}>
          <div className="form-title">
            <span className="required">必須</span>
            <label>権限</label>
          </div>
          <select className="role" {...register("role")}>
            <option value="1">店舗管理者</option>
          </select>
        </div>
        {/* 条項 */}
        {errors.consentFlg && (
          <p className="alert">{errors.consentFlg?.message}</p>
        )}
        <div className="term">
          <input
            {...register("consentFlg", {
              required: consentError,
            })}
            type="checkbox"
            id="consentFlg"
          />
          <label htmlFor="consentFlg" className="checkbox"></label>
          <div className="terms_policy">
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}/service/policy`}
              >
                利用規約
              </a>
              と
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}/privacy/policy`}
              >
                プライバシーポリシー
              </a>
              を読み内容に同意します。
            </p>
          </div>
        </div>
      </form>
      <div className="button_center">
        <button
          type="submit"
          className="normal_btn"
          onClick={handleSubmit(onSubmit)}
        >
          店舗登録へ
        </button>
      </div>
    </div>
  );
};
