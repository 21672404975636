import React from "react";
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
type props = {
  element: React.ReactNode;
};
export const RouteAuthGuard = (props: props) => {
  const [cookies] = useCookies(["auth_id"]);
  if (cookies.auth_id) {
    return <>{props.element}</>;
  } else {
    return <Navigate to={`/login`} replace={false} />;
  }
};
