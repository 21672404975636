import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomApi } from "../../hooks/useAPi";
import {
  requiredError,
  maxLengthError,
  maxLengthNumberError,
  patternNumber,
  patternPhoneNumber,
  patternBankName,
  patternUrl,
} from "../../const/validationMessage";
import { typeShop } from "../../types";
import { Title } from "../../components/common/title";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useCookies } from "react-cookie";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import master from "../../assets/img/mc_symbol_opt_73_3x.png";
import visa from "../../assets/img/Visa_Brandmark_Blue_RGB_2021.png";
import amex from "../../assets/img/amex-logomark-img-06.png";
import jcb from "../../assets/img/jcb.png";
import dinars from "../../assets/img/diners.png";

type cardReducer = {
  cvc: { complete: boolean; message: string };
  cardNumber: { complete: boolean; message: string };
  expiry: { complete: boolean; message: string };
};

export const StoreRegist = () => {
  const stripe = useStripe();
  const elements = useElements();

  const INIT_CARD_REDUCER = {
    cvc: { complete: false, message: "" },
    cardNumber: { complete: false, message: "" },
    expiry: { complete: false, message: "" },
  };

  const [cardState, cardDispatch] = useReducer(
    (
      state: cardReducer,
      {
        type,
        data,
      }: {
        type: "cvc" | "cardNumber" | "expiry";
        data:
          | StripeCardNumberElementChangeEvent
          | StripeCardExpiryElementChangeEvent
          | StripeCardCvcElementChangeEvent;
      }
    ): cardReducer => {
      switch (type) {
        case "cvc":
          if (data.error) {
            return {
              ...state,
              [type]: { complete: data.complete, message: data.error.message },
            };
          } else {
            return {
              ...state,
              [type]: { complete: data.complete, message: "" },
            };
          }
        case "cardNumber":
          if (data.error) {
            return {
              ...state,
              [type]: { complete: data.complete, message: data.error.message },
            };
          } else {
            return {
              ...state,
              [type]: { complete: data.complete, message: "" },
            };
          }
        case "expiry":
          if (data.error) {
            return {
              ...state,
              [type]: { complete: data.complete, message: data.error.message },
            };
          } else {
            return {
              ...state,
              [type]: { complete: data.complete, message: "" },
            };
          }
        default:
          return INIT_CARD_REDUCER;
      }
    },

    INIT_CARD_REDUCER
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["admin_id", "auth_id"]);

  const [dialog, setDialog] = useState<boolean>(false);
  const [memberFlg, setMemberFlg] = useState<0 | 1>(0);
  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    // setValue,
  } = useForm<typeShop>({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const onSubmit = async (values: typeShop) => {
    dispatch({ type: "loading", payload: true });
    if (memberFlg === 0) {
      const paymentToken = await stripe?.createToken(
        elements!.getElement("cardNumber")!
      );
      values.cardToken = paymentToken!.token!.id;
    }

    values.admin_id = Number(searchParams.get("id"));
    values.member_flag = memberFlg;

    const data: any = await CustomApi<typeShop>(
      "store/create",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200 && data.status !== 400) {
      navigate("/error");
    } else if (data.status === 400) {
      alert(data.data.responsMassage);
    } else if (data.status === 200) {
      setDialog(true);
    }
  };

  //住所検索API
  // const getAddress = async () => {
  //   const res = await axios.get(
  //     "https://api.zipaddress.net/?zipcode=" + getValues("post_number")
  //   );
  //   if (res.data.code === 200) {
  //     const { pref, address } = res.data.data;

  //     setValue("prefectures", pref);
  //     setValue("municipalities", address);
  //   } else {
  //     alert("郵便番号が見つかりません");
  //   }
  // };
  useEffect(() => {
    setMemberFlg(searchParams.get("key") === "orc2023" ? 1 : 0);
  }, []);
  return (
    <div className="cont_wrapper">
      <Title title={"店舗登録"} />
      <form>
        {/* 店舗名 */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>店舗名</p>
          </div>
          {errors.store_name && (
            <p className="alert">{errors.store_name?.message}</p>
          )}
          <input
            {...register("store_name", {
              required: requiredError,
              maxLength: maxLengthError(16),
            })}
            type="text"
            src=""
            placeholder="店舗名 を入力してください"
            alt=""
          />
        </div>
        {/* 店舗URL */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>EnCas内で使用するURLの設定</p>
          </div>
          <p style={{ fontSize: "12px", padding: "10px" }}>
            shop.encas.jp/●●● ←この部分を設定してください。
            <br></br>
            （店舗の宣伝用URLになります）
          </p>

          {errors.store_url && (
            <p className="alert">{errors.store_url?.message}</p>
          )}
          <input
            {...register("store_url", {
              required: requiredError,
              maxLength: maxLengthError(12),
              pattern: patternUrl,
            })}
            type="text"
            src=""
            placeholder="店舗URL ( xxx ) を入力してください"
            alt=""
          />
        </div>
        {/* 電話番号 */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>電話番号(ハイフンなし)</p>
          </div>

          {errors.phone_number && (
            <p className="alert">{errors.phone_number?.message}</p>
          )}
          <input
            {...register("phone_number", {
              required: requiredError,
              pattern: patternPhoneNumber,
            })}
            type="text"
            src=""
            placeholder="電話番号 を入力してください"
            alt=""
          />
        </div>
        {/* 郵便番号 */}
        <div>
          <div className="post_number">
            <div className="form-title">
              <span className="required">必須</span>
              <label htmlFor="zipCode" className="form-label">
                郵便番号(ハイフンなし)
              </label>
            </div>
            {errors.post_number && (
              <p className="alert">{errors.post_number?.message}</p>
            )}
            <div className="form_post_flex">
              <input
                {...register("post_number", {
                  required: requiredError,
                  maxLength: maxLengthError(7),
                  pattern: patternNumber,
                })}
                type="text"
                src=""
                placeholder="例：1234567"
                alt=""
              />
              {/* <button
                type="button"
                onClick={() => getAddress()}
                className="normal_btn"
              >
                住所検索
              </button> */}
            </div>
          </div>
        </div>
        {/* 都道府県 */}
        {/* <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>都道府県</p>
          </div>
          {errors.prefectures && (
            <p className="alert">{errors.prefectures?.message}</p>
          )}
          <input
            {...register("prefectures", {
              required: requiredError,
              maxLength: maxLengthError(50),
            })}
            type="text"
            src=""
            placeholder=""
            alt=""
          />
        </div> */}
        {/* 市区町村 */}
        {/* <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>市区町村</p>
          </div>

          {errors.municipalities && (
            <p className="alert">{errors.municipalities?.message}</p>
          )}
          <input
            {...register("municipalities", {
              required: requiredError,
              maxLength: maxLengthError(50),
            })}
            type="text"
            src=""
            placeholder=""
            alt=""
          />
        </div> */}
        {/* 番地 */}
        {/* <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>番地</p>
          </div>
          {errors.streets && <p className="alert">{errors.streets?.message}</p>}
          <input
            {...register("streets", {
              required: requiredError,
              maxLength: maxLengthError(50),
            })}
            type="text"
            src=""
            placeholder=""
            alt=""
          />
        </div> */}
        {/* 建物名 */}
        {/* <div>
          <div className="form-title">
            <span className="any">任意</span>
            <p>建物名</p>
          </div>

          {errors.building && (
            <p className="alert">{errors.building?.message}</p>
          )}
          <input
            {...register("building", {})}
            type="text"
            src=""
            placeholder=""
            alt=""
          />
        </div> */}
        {/* 代表者名 */}
        <div>
          <div className="form-title">
            <span className="required">必須</span>
            <p>代表者名</p>
          </div>
          {errors.rep_name && (
            <p className="alert">{errors.rep_name?.message}</p>
          )}
          <input
            {...register("rep_name", {
              required: requiredError,
              maxLength: maxLengthError(50),
            })}
            type="text"
            src=""
            placeholder="代表者名 を入力してください"
            alt=""
          />
        </div>
        {/* 出金申請用口座情報 */}
        <div className="form-title">
          <p className="required">必須</p>
          <label htmlFor="account">出金申請用口座情報</label>
        </div>
        <div>
          {/* 銀行 */}
          {errors.bank_name && (
            <p className="alert" style={{ textAlign: "right" }}>
              {errors.bank_name?.message}
            </p>
          )}
          <div className="bank_name">
            <input
              type="text"
              id="bank_name"
              placeholder="銀行名"
              {...register("bank_name", {
                required: requiredError,
                maxLength: maxLengthError(15),
              })}
            />
            <p className="bank_text right_space">銀行</p>
          </div>
          {/* 支店 */}
          {errors.branch_name && (
            <p className="alert" style={{ textAlign: "right" }}>
              {errors.branch_name?.message}
            </p>
          )}
          <div className="branch_name">
            <input
              type="text"
              id="branch_name"
              placeholder="支店名"
              {...register("branch_name", {
                required: requiredError,
                maxLength: maxLengthError(15),
              })}
            />
            <p className="bank_text right_space">支店</p>
          </div>
          {/* 口座番号 */}
          {errors.account_number && (
            <p className="alert" style={{ textAlign: "right" }}>
              {errors.account_number?.message}
            </p>
          )}
          <div className="account_num">
            <div className="select_wrap bank_type">
              <select {...register("account_type")}>
                <option value="普通">普通</option>
                <option value="当座">当座</option>
              </select>
            </div>
            <input
              type="text"
              id="account_number"
              placeholder="口座番号"
              {...register("account_number", {
                required: requiredError,
                maxLength: maxLengthError(8),
                pattern: patternNumber,
              })}
            />
          </div>
          {/* 口座名義 */}
          {errors.account_name && (
            <p className="alert" style={{ textAlign: "right" }}>
              {errors.account_name?.message}
            </p>
          )}
          <div className="account_name">
            <input
              type="text"
              id="account_name"
              placeholder="口座名義(カナ)"
              {...register("account_name", {
                required: requiredError,
                maxLength: maxLengthNumberError(50),
                pattern: patternBankName,
              })}
            />
          </div>
        </div>
        {/* stripe */}
        {memberFlg === 0 ? (
          <>
            <div className="form-title">
              <p className="required">必須</p>
              <label htmlFor="account">クレジットカード情報</label>
              <p className="card_annotation">
                ※システム利用料として月額11,000円（税込）がかかります。
              </p>
              <div className="card_icon_list">
                <img src={master} alt="" />
                <img src={visa} alt="" />
                <img className="amex" src={amex} alt="" />
                <img src={jcb} alt="" />
                <img src={dinars} alt="" />
              </div>
            </div>
            {cardState.cardNumber.message ? (
              <p className="alert">{cardState.cardNumber.message}</p>
            ) : (
              ""
            )}
            {cardState.expiry.message ? (
              <p className="alert">{cardState.expiry.message}</p>
            ) : (
              ""
            )}
            {cardState.cvc.message ? (
              <p className="alert">{cardState.cvc.message}</p>
            ) : (
              ""
            )}
            <div className="card_number_area">
              <CardNumberElement
                options={{
                  iconStyle: "solid",
                  style: {
                    base: {
                      fontSize: "16px",
                      letterSpacing: "1px",
                      color: "#b0b0ae",
                      "::placeholder": {
                        color: "#b0b0ae",
                      },
                    },
                  },
                }}
                onChange={(e) => cardDispatch({ type: "cardNumber", data: e })}
              />
            </div>
            <div className="card_expiry_cvc_area">
              <div className="card_expiry_element">
                <div className="form-title">
                  <span className="required">必須</span>
                  <label>有効期限</label>
                </div>
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        letterSpacing: "1px",
                        color: "#b0b0ae",
                        "::placeholder": {
                          color: "#b0b0ae",
                        },
                      },
                    },
                  }}
                  onChange={(e) => cardDispatch({ type: "expiry", data: e })}
                />
              </div>
              <div className="card_cvc_element">
                <div className="form-title">
                  <span className="required">必須</span>
                  <label>CVC情報</label>
                </div>
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        letterSpacing: "1px",
                        color: "#b0b0ae",
                        "::placeholder": {
                          color: "#b0b0ae",
                        },
                      },
                    },
                  }}
                  onChange={(e) => cardDispatch({ type: "cvc", data: e })}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </form>
      <div className="button">
        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="normal_btn"
          disabled={
            memberFlg === 0 &&
            (!cardState.cvc.complete ||
              !cardState.cardNumber.complete ||
              !cardState.expiry.complete)
          }
        >
          登録
        </button>
      </div>
      {dialog ? (
        <BaseDialog type="complete" close={() => navigate("/login")} />
      ) : (
        ""
      )}
    </div>
  );
};
