type props = {
  close: () => void;
  success: () => void;
};
export const OrderConfirm = (props: props) => {
  return (
    <div id="orderConfirm">
      <p className="dialogText">提供済にしますか？</p>
      <div className="button_flex">
        <button className="outline_pink_btn" onClick={props.close}>
          キャンセル
        </button>
        <button onClick={props.success} className="normal_btn">
          完了
        </button>
      </div>
    </div>
  );
};
