import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomApi } from "../../hooks/useAPi";
import {
  requiredError,
  minLengthError,
  maxLengthError,
  patternHankaku,
  patternAddress,
} from "../../const/validationMessage";
import { login } from "../../types";
import { Title } from "../../components/common/title";
import { useCookies } from "react-cookie";
import { Footer } from "../../components/footer/footer";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    "role",
    "store_id",
    "store_name",
    "store_url",
    "auth_id",
    "admin_id",
  ]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<login>({
    mode: "onChange",
    criteriaMode: "all",
  });

  //ログイン成功
  const onSubmit: SubmitHandler<login> = async (values: login) => {
    dispatch({ type: "loading", payload: true });
    let limit = new Date();
    // ログイン状態を1週間指定する
    limit.setDate(limit.getDate() + 7);
    const data = await CustomApi<login>(
      "admin/login",
      values,
      cookies.auth_id,
      cookies.admin_id
    );

    if (data.status !== 200) {
      alert("ログインに失敗しました。再度やり直してください");
      reset();
      dispatch({ type: "loading", payload: false });
      return;
    }
    setCookie("store_id", data.data.store_id, {
      expires: limit,
    });
    setCookie("role", data.data.role, { expires: limit });
    setCookie("store_name", data.data.store_name, {
      expires: limit,
    });
    setCookie("store_url", data.data.store_url, {
      expires: limit,
    });
    setCookie("auth_id", data.data.auth_id, {
      expires: limit,
    });
    setCookie("admin_id", data.data.admin_id, {
      expires: limit,
    });
    dispatch({ type: "loading", payload: false });
    if (data.data.role === 3) {
      navigate("/store/list");
    } else {
      navigate("/product/list");
    }
  };

  return (
    <>
      <div className="cont_wrapper">
        <Title title={"ログイン"} />

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* メールアドレス */}
          <div>
            <div className="form-title">
              <span className="required">必須</span>
              <label htmlFor="email">メールアドレス</label>
            </div>
            {errors.email && <p className="alert">{errors.email?.message}</p>}
            <input
              {...register("email", {
                required: requiredError,
                maxLength: maxLengthError(255),
                pattern: patternAddress,
              })}
              type="email"
              id="email"
              src=""
              placeholder="メールアドレス を入力してください"
              alt=""
            />
          </div>
          {/* パスワード */}
          <div>
            <div className="form-title">
              <span className="required">必須</span>
              <label htmlFor="password">パスワード</label>
            </div>

            {errors.password && (
              <p className="alert">{errors.password?.message}</p>
            )}
            <input
              {...register("password", {
                required: requiredError,
                minLength: minLengthError(8),
                maxLength: maxLengthError(64),
                pattern: patternHankaku,
              })}
              type="password"
              id="password"
              src=""
              placeholder="半角英数字8桁以上 で入力してください"
              alt=""
            />
          </div>
        </form>
        <div className="button_center">
          <button
            type="submit"
            className="normal_btn"
            onClick={handleSubmit(onSubmit)}
          >
            ログイン
          </button>
        </div>
        <div className="login_option">
          <p>
            パスワードを忘れた方は<Link to="/mail/reset">こちら</Link>
          </p>
          <p>
            新規登録は<Link to={`/mail/register`}>こちら</Link>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};
