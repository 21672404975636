import React, { useState, useEffect } from "react";
import { Title } from "../../components/common/title";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { CustomApi } from "../../hooks/useAPi";
import { typePaymentRequest } from "../../types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export const PaymentRequest = () => {
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = new Date();
  const getPaymentData = async () => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{ store_id: number }>(
      "sales/apply/show",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    setData(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  };
  useEffect(() => {
    getPaymentData();
  }, []);
  const [dialog, setDialog] = useState<boolean>(false);
  const [data, setData] = useState<typePaymentRequest>({
    apply_amount: 0,
  });
  const [dialogType, setDialogType] = useState<"paymentConfirm" | "complete">(
    "paymentConfirm"
  );

  const closeDialog = () => {
    getPaymentData();
    setDialog(!dialog);
  };
  const successDialog = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{ store_id: number }>(
      "sales/apply/create",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200) {
      navigate("/error");
    }
    setDialogType("complete");
  };
  return (
    <div className="payment_request cont_wrapper">
      <div className="request_view">
        <Title title={"出金申請"} />

        <div className="payment_Request_inner">
          <p className="payment_alert alert">
            ※前月の売上からシステム手数料及び決済手数料を引いた金額が申請できます。
            <br></br>
            売上が1,000円未満の場合は翌月に繰り越されます。
            <br></br>
            毎月1日〜15日の間に出金申請をしてください。
          </p>
          <div className="payment_request_contents">
            <div className="form-title">
              <h2>出金可能額</h2>
            </div>
            <h3>¥{data.apply_amount}</h3>
          </div>
        </div>
        <div className="button_flex">
          <button
            onClick={() => navigate("/payment/history")}
            className="outline_pink_btn"
            type="button"
          >
            戻る
          </button>
          <button
            className="normal_btn"
            type="button"
            disabled={data.apply_amount! < 1000 || today.getDate() >= 15}
            onClick={() => setDialog(!dialog)}
          >
            申請
          </button>
        </div>
      </div>
      {dialog ? (
        <BaseDialog
          type={dialogType}
          close={() => closeDialog()}
          success={() => successDialog()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
