import axios from "axios";
export const CustomApi = async <T,>(
  url: string,
  param: T,
  auth_id: string,
  admin_id: number
) => {
  try {
    const response = await axios.post<T>(
      `${process.env.REACT_APP_BASE_API_URL}${url}`,
      param,
      {
        headers: {
          "content-type": "multipart/form-data",
          "auth-id": auth_id,
          "type-id": "admin",
          "user-id": admin_id,
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response!;
  }
};
