import React, { useState, useEffect } from "react";
import { reWiteDate } from "../../hooks/useReDate";
import { CustomApi } from "../../hooks/useAPi";
import { typeAdminPaymentList } from "../../types/index";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const PaymentList = () => {
  const [cookies] = useCookies(["admin_id", "auth_id"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<"request" | "history">(
    "request"
  );
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<
    "paymentCompleteConfirm" | "complete"
  >("paymentCompleteConfirm");
  // APIからの取得結果を保有するステート
  const [data, setData] = useState<typeAdminPaymentList[]>([]);
  const [propsDialog, setPropsDialog] = useState<typeAdminPaymentList["id"]>();

  // タブ切り替えによって、フィルター実行後のステート
  const [paymentList, setPaymentList] = useState<typeAdminPaymentList[]>([]);

  const getPaymentList = async () => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{}>(
      "adminpayment/index",
      {},
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      dispatch({ type: "loading", payload: false });
      navigate("/error");
    }
    setData(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  };

  const changeTabFilter = () => {
    const filterData = data.filter((item) => {
      if (currentTab === "request") {
        return item.transfer_status === 0;
      } else if (currentTab === "history") {
        return item.transfer_status === 1;
      }
    });
    setPaymentList(filterData);
  };

  const closeDialog = () => {
    getPaymentList();
    setDialog(!dialog);
    setDialogType("paymentCompleteConfirm");
  };
  const clickComplete = (item: typeAdminPaymentList["id"]) => {
    setPropsDialog(item);
    setDialog(!dialog);
  };
  const updatePayment = async () => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{ id: number; transfer_status: 1 }>(
      "adminpayment/update",
      {
        id: propsDialog!,
        transfer_status: 1,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      navigate("/error");
    }
    dispatch({ type: "loading", payload: false });
    setDialogType("complete");
  };
  useEffect(() => {
    getPaymentList();
  }, []);
  useEffect(() => {
    // Filter機能の実装
    changeTabFilter();
  }, [currentTab, data]);
  return (
    <div id="paymentList" className="cont_wrapper wide_cont_wrapper">
      <div className="switch_button_area">
        <button
          className={currentTab === "request" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("request")}
        >
          出金申請
        </button>
        <span></span>
        <button
          className={currentTab === "history" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("history")}
        >
          出金履歴
        </button>
      </div>
      <div className="table_wrap">
        <table className="table">
          <tbody>
            <tr>
              <th>申請No</th>
              <th>申請店舗名</th>
              <th>申請日</th>
              <th>申請可能額</th>
              <th>申請額</th>
              <th>口座情報</th>
              <th>出金完了日</th>
            </tr>
            {paymentList.map((item, i) => (
              <tr key={i}>
                <td>{item.id}</td>
                <td>{item.store_name}</td>
                <td>{reWiteDate(item.created_at)}</td>
                <td>¥{item.apply_amount}</td>
                <td>¥{item.applied_amount}</td>
                <td>
                  {item.bank_name}
                  <br />
                  {item.branch_name}
                  <br />
                  {item.account_type}
                  <br />
                  {item.account_number}
                  <br />
                  {item.account_name}
                </td>
                <td>
                  {item.transfer_status !== 0 ? (
                    reWiteDate(item.updated_at)
                  ) : (
                    <button
                      onClick={() => clickComplete(item.id)}
                      className="outline_pink_btn"
                    >
                      完了
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {dialog ? (
        <BaseDialog
          type={dialogType}
          close={() => closeDialog()}
          success={() => updatePayment()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
