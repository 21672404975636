import React, { Dispatch, SetStateAction } from "react";
import { Title } from "../../../components/common/title";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  requiredError,
  maxLengthError,
  patternAddress,
} from "../../../const/validationMessage";
import { CustomApi } from "../../../hooks/useAPi";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

/**
 * メール登録オブジェクトの型定義
 */
type mailRegister = {
  email: string;
  store_url: string;
};
type props = {
  changeState: () => void;
  setAddress: Dispatch<SetStateAction<mailRegister["email"]>>;
};
export const MailForm = (props: props) => {
  const params = useParams();
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm<mailRegister>({
    criteriaMode: "all",
  });
  const onSubmit = async (values: mailRegister) => {
    dispatch({ type: "loading", payload: true });
    values.store_url = params.store!;
    const data = await CustomApi<mailRegister>(
      "admin/create",
      values,
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200 && data.status !== 400) {
      alert("なんらかの問題が発生しました。再度送信を行ってください");
      window.location.reload();
      return;
    } else if (data.status === 400) {
      alert(
        `${data.data.responsMassage}
他のメールアドレスをご利用ください`
      );
      window.location.reload();
      return;
    } else if (data.status === 200) {
      props.setAddress(values.email);
      dispatch({ type: "loading", payload: false });
      // 完了したら画面切り替え
      props.changeState();
    }
  };
  return (
    <>
      <Title title={"メール登録"} />
      <form>
        {/* メールアドレス */}
        <div>
          <p>登録するメールアドレスを入力してください</p>
        </div>
        <div className="form-title">
          <span className="required">必須</span>
          <label htmlFor="email">メールアドレス</label>
        </div>
        {errors.email && <p className="alert">{errors.email?.message}</p>}
        <input
          {...register("email", {
            required: requiredError,
            maxLength: maxLengthError(255),
            pattern: patternAddress,
            onBlur: () => {
              if (getValues("email")) {
                trigger("email");
              }
            },
            validate: (value) => {
              return (
                value === getValues("email") || "メールアドレスが一致しません"
              );
            },
          })}
          type="email"
          id="email"
          src=""
          placeholder="メールアドレス を入力してください"
          alt=""
        />
      </form>
      <div className="button_flex">
        <Link to={"/login"} className="outline_pink_btn">
          戻る
        </Link>
        <button
          type="submit"
          className="normal_btn"
          onClick={handleSubmit(onSubmit)}
        >
          送信する
        </button>
      </div>
    </>
  );
};
