import { useNavigate } from "react-router-dom";

export const PassResetCompleted = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="guide">
        <span>
          <p>パスワードの設定が完了しました。</p>
        </span>
      </div>
      <div className="button_center">
        <button
          type="submit"
          className="normal_btn"
          onClick={() => navigate("/login")}
        >
          ログイン画面へ
        </button>
      </div>
    </>
  );
};
