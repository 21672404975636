import React, { useState, useEffect } from "react";
import { CustomApi } from "../../hooks/useAPi";
import { typeOrdersList } from "../../types/index";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export const OrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<"order" | "offered">("order");
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<"orderConfirm" | "complete">(
    "orderConfirm"
  );

  // APIからの取得結果を保有するステート
  const [data, setData] = useState<typeOrdersList[]>([]);
  // タブ切り替えによって、フィルター実行後のステート
  const [orderList, setOrderList] = useState<typeOrdersList[]>([]);
  const [propsId, setPropsId] = useState<typeOrdersList["id"]>();
  const [cookies] = useCookies(["store_id", "auth_id", "admin_id"]);

  const getOrderList = async () => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{
      store_id: number;
    }>(
      "order/confirmation",
      {
        store_id: cookies.store_id,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    setData(data.data.responsData);
    dispatch({ type: "loading", payload: false });
  };
  const changeTabFilter = () => {
    const filterData = data.filter((item) => {
      if (currentTab === "order") {
        return item.serve_flag === 0;
      } else if (currentTab === "offered") {
        return item.serve_flag === 1;
      }
    });
    setOrderList(filterData);
  };
  const closeDialog = () => {
    getOrderList();
    setDialogType("orderConfirm");
    setDialog(!dialog);
  };
  const clickOffered = (item: typeOrdersList["id"]) => {
    setPropsId(item);
    setDialog(!dialog);
  };

  const changeStatus = async () => {
    dispatch({ type: "loading", payload: true });
    const data = await CustomApi<{ id: number }>(
      "order/complete",
      {
        id: propsId!,
      },
      cookies.auth_id,
      cookies.admin_id
    );
    dispatch({ type: "loading", payload: false });
    if (data.status !== 200) {
      navigate("/error");
    }
    setDialogType("complete");
  };

  useEffect(() => {
    getOrderList();
  }, []);
  useEffect(() => {
    // Filter機能の実装
    changeTabFilter();
  }, [currentTab, data]);
  return (
    <div id="paymentList" className="cont_wrapper wide_cont_wrapper">
      <div className="switch_button_area">
        <button
          className={currentTab === "order" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("order")}
        >
          注文
        </button>
        <span></span>
        <button
          className={currentTab === "offered" ? "current" : ""}
          type="button"
          onClick={() => setCurrentTab("offered")}
        >
          提供済
        </button>
      </div>
      <div className="table_wrap">
        <table className="table">
          <tbody>
            <tr>
              <th>注文No</th>
              <th>商品画像</th>
              <th>注文商品</th>
              <th>注文金額</th>
              <th>注文数</th>
              <th>注文者</th>
              <th>TwitterID</th>
              <th>InstagramID</th>
              <th>メッセージ</th>
              <th>ステータス</th>
            </tr>
            {orderList.map((item, i) => (
              <tr key={i}>
                <td>{item.id}</td>
                <td className="orderListImage">
                  <img src={item.image} alt="" />
                </td>
                <td>{item.product_name}</td>
                <td>¥{item.amount.toLocaleString()}</td>
                <td>{item.quantity.toLocaleString()}</td>
                <td>{item.nickname}</td>
                <td>{item.twitterid}</td>
                <td>{item.instagramid}</td>
                <td>{item.message}</td>
                <td>
                  {item.serve_flag === 0 ? (
                    <button
                      onClick={() => clickOffered(item.id)}
                      className="outline_pink_btn"
                    >
                      完了
                    </button>
                  ) : (
                    <p className="order_grey">提供済</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {dialog ? (
        <BaseDialog
          type={dialogType}
          close={() => closeDialog()}
          success={() => changeStatus()}
        />
      ) : (
        ""
      )}
    </div>
  );
};
