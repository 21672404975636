import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Title } from "../../components/common/title";
import { Pagination } from "../../components/common/pagination";
import { BaseDialog } from "../../components/dialog/baseDialog";
import { typeShop } from "../../types";
import { CustomApi } from "../../hooks/useAPi";
import { useCookies } from "react-cookie";

export const StoreList = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["admin_id", "auth_id"]);
  const navigate = useNavigate();

  // 何番目のページか保有する
  const [currentPage, setCurrentPage] = useState<number>(1);

  // 表示用のステート(ページネーション毎に表示数を保有する)
  const [showStoreList, setShowStoreList] = useState<typeShop[]>([]);

  // フィルター結果を保有するステート(フィルター結果を保持して、showEmployeeListステートに表示ページ毎のデータを登録する)
  const [storeList, setStoreList] = useState<typeShop[]>([]);

  // ページネーションのために、何件データがあるかを保有する
  const [StoreLength, setStoreLength] = useState<number>(0);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogDetailData, setDialogDetailData] = useState<typeShop>();
  // 1ページあたりの表示数
  const maxStoreView = 10;
  const getStoreLists = async () => {
    dispatch({ type: "loading", payload: true });

    const data = await CustomApi<{}>(
      "store/index",
      {},
      cookies.auth_id,
      cookies.admin_id
    );
    if (data.status !== 200) {
      dispatch({ type: "loading", payload: false });
      navigate("/error");
      return;
    }
    setStoreList(data.data.responsData);
    setStoreLength(data.data.responsData.length);

    dispatch({ type: "loading", payload: false });
  };
  const currentStoreLists = () => {
    let pageList: typeShop[] = [];
    storeList.map((store, i) => {
      // 表示分のみをフィルターして表示する
      // (ページ1 -1 )*10 = 0
      // ページ1 * 10 -1 = 9
      // 0番目から表示されるため最大数より1件ぶん引く
      if (
        (currentPage - 1) * maxStoreView <= i &&
        currentPage * maxStoreView - 1 >= i
      ) {
        pageList.push(store);
      }
    });
    setShowStoreList(pageList);
  };
  useEffect(() => {
    getStoreLists();
  }, []);

  useEffect(() => {
    currentStoreLists();
  }, [storeList, currentPage]);

  const setDetail = (data: typeShop) => {
    setDialogDetailData(data);
    setDialog(true);
  };

  return (
    <div id="storeList" className="cont_wrapper">
      <div className="regist_button_area">
        <Title title={"店舗管理"} />
      </div>

      <div>
        <table className="table">
          <tr>
            <th>店舗ID</th>
            <th>店舗名</th>
            <th>店舗ページ</th>
            <th></th>
          </tr>
          {showStoreList.map((store, i) => (
            <tr key={i}>
              <td>{store.id}</td>
              <td>{store.store_name}</td>
              <td>{store.store_url}</td>
              <td>
                <div className="detail_button_area">
                  <button
                    className="detail_button outline_pink_btn"
                    type="button"
                    onClick={() => setDetail(store)}
                  >
                    詳細
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>

      {StoreLength > maxStoreView ? (
        <Pagination
          currentPage={currentPage}
          pageLength={StoreLength}
          maxView={maxStoreView}
          setCurrent={setCurrentPage}
        />
      ) : null}
      {dialog ? (
        <BaseDialog
          type="storeDetail"
          close={() => setDialog(!dialog)}
          item={dialogDetailData!}
        />
      ) : (
        ""
      )}
    </div>
  );
};
